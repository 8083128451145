import { IS_DEVELOPMENT } from '@shiftsmartinc/remix-utils';
import { lazy } from 'react';

/**
 * We'll lazy load RemixDevTools to ensure it doesn't contribute to the
 * production bundle size
 */
const RemixDevTools = IS_DEVELOPMENT
  ? lazy(() => import('remix-development-tools'))
  : () => null;

export interface GlobalRemixDevToolsProps {}

/**
 * @name GlobalRemixDevTools
 * @external https://www.npmjs.com/package/remix-development-tools
 * @description Looks like this may be pretty useful for debugging the
 * state available in our loaders, but its still pretty new and it does
 * appear to prevent 🔥 HMR (hot module reloading) from working.
 */
export const GlobalRemixDevTools = (_props: GlobalRemixDevToolsProps) => {
  // Hooks

  // Setup
  const isEnabled = false;

  // Handlers

  // Markup

  // Life Cycle

  // 🔌 Short Circuits
  if (!IS_DEVELOPMENT || !isEnabled) return null;

  return (
    <RemixDevTools
      additionalTabs={[]}
      defaultOpen={false}
      hideUntilHover={false}
      position="bottom-right"
    />
  );
};
