import { ReactElement } from 'react';
import { ModalProvider } from '@shiftsmartinc/react-hooks';
import {
  GlobalAppContext,
  GlobalAppContextShape,
} from '~/global/context/GlobalAppContext';

export interface GlobalContextProps {
  children: ReactElement | ReactElement[];
  globalAppContext: GlobalAppContextShape;
}

/**
 * @name GlobalContext
 * @description We use this at the root our our application passing it the
 * GlobalApp. We could pass it in here directly, but it's a bit more clear
 * to pass it in as a child.
 */
export const GlobalContext = (props: GlobalContextProps) => {
  const { children, globalAppContext } = props;

  // Hooks

  // Setup

  // Handlers

  // Markup

  // Life Cycle

  // 🔌 Short Circuits

  return (
    <GlobalAppContext.Provider value={globalAppContext}>
      <ModalProvider>
        <>{children}</>
      </ModalProvider>
    </GlobalAppContext.Provider>
  );
};
