import { Avatar } from '@shiftsmartinc/shiftsmart-ui';
import { Bell, MagnifyingGlass, SignOut } from '@phosphor-icons/react';
import { NavLink, NavLinkProps } from '@remix-run/react';
import classnames from 'classnames';
import { useTypedRouteLoaderData } from 'remix-typedjson';
import { links } from '~/services/navigation/data/navigation.primary';
import { NavigationProjects } from '~/services/navigation/components/NavigationProjects';
import { RootLoaderData } from '~/root';
import * as datadog from '~/global/config/datadog';

export interface NavigationPrimaryProps {}

/**
 * @name NavigationPrimary
 * @description TODO: Add a description
 */
export const NavigationPrimary = (_props: NavigationPrimaryProps) => {
  // Hooks
  const { user } = useTypedRouteLoaderData('root') as RootLoaderData;

  // Setup

  // Handlers

  // Markup
  const renderLink = (link: NavLinkProps) => {
    return (
      <NavLink
        {...link}
        className={({ isActive }) => {
          return classnames(
            'whitespace-nowrap rounded-md px-[10px] py-[6px] text-sm transition-colors duration-300',
            {
              'bg-brand-lighter text-brand': isActive,
              'text-gray-2 hover:bg-brand hover:text-white': !isActive,
            },
            link.className?.toString(),
          );
        }}
        key={link.to.toString()}
        prefetch="intent"
      />
    );
  };

  // Life Cycle

  // 🔌 Short Circuits

  return (
    <div className="z-20 flex items-center gap-1 border-b border-b-gray-8 py-3">
      {/* Logo back to the home page */}
      <NavLink to="/">
        <img
          alt=""
          className="ml-6 mr-6 h-8"
          role="img"
          src="/branding/shiftsmart/icon.svg"
        />
        <span className="hidden">Home</span>
      </NavLink>

      {/* And loop over our links */}
      {links.slice(0, 3).map(renderLink)}
      <NavigationProjects />
      {links.slice(3).map(renderLink)}

      {/* Spacer */}
      <div className="flex-1" />

      {/* User "actions" with icons */}
      <NavLink
        className={({ isActive }) =>
          classnames('mr-4', {
            'text-brand': isActive,
            'text-gray-2 hover:text-gray-5': !isActive,
          })
        }
        prefetch="intent"
        to="/search"
      >
        <MagnifyingGlass className="h-5 w-5" />
        <span className="hidden">Search</span>
      </NavLink>

      <NavLink
        className={({ isActive }) =>
          classnames('mr-4', {
            'text-brand': isActive,
            'text-gray-2 hover:text-gray-5': !isActive,
          })
        }
        prefetch="intent"
        to="/profile/notifications"
      >
        <Bell className="h-6 w-6" />
        <span className="hidden">Notifications</span>
      </NavLink>

      {/* 🚨 Important 🚨 Note the use of "reloadDocument" below */}
      <NavLink
        className="mr-4 text-gray-2 hover:text-gray-5"
        onClick={datadog.deleteUser}
        reloadDocument={true}
        to="/auth/logout"
      >
        <SignOut className="h-6 w-6" />
        <span className="hidden">Logout</span>
      </NavLink>

      <NavLink className="mr-8" prefetch="intent" to="/profile">
        <Avatar
          name={user.displayName ?? ''}
          size="xs"
          src={user.profileImageURL}
        />
        <span className="hidden">Profile</span>
      </NavLink>
    </div>
  );
};
