import type { NavLinkProps } from '@remix-run/react';

export interface Data extends NavLinkProps {}

export const links: NavLinkProps[] = [
  {
    children: 'Home',
    className: 'pointer-events-none text-gray-6', // Remove to re-activate the link
    prefetch: 'intent',
    to: '/',
  },
  {
    children: 'Shifts',
    prefetch: 'intent',
    to: '/shifts',
  },
  {
    children: 'Partners',
    prefetch: 'intent',
    to: '/partners',
  },
  {
    children: 'Tasks',
    className: 'pointer-events-none text-gray-6', // Remove to re-activate the link
    prefetch: 'intent',
    to: '/tasks',
  },
  {
    children: 'Onboarding',
    className: 'pointer-events-none text-gray-6', // Remove to re-activate the link
    prefetch: 'intent',
    to: '/onboarding',
  },
  {
    children: 'Development',
    className: 'pointer-events-none text-gray-6', // Remove to re-activate the link
    prefetch: 'intent',
    to: '/development',
  },
  {
    children: 'Payments',
    className: 'pointer-events-none text-gray-6', // Remove to re-activate the link
    prefetch: 'intent',
    to: '/payments',
  },
  {
    children: 'Messaging',
    className: 'pointer-events-none text-gray-6', // Remove to re-activate the link
    prefetch: 'intent',
    to: '/messaging',
  },
  {
    children: 'Support',
    className: 'pointer-events-none text-gray-6', // Remove to re-activate the link
    prefetch: 'intent',
    to: '/support',
  },
  {
    children: 'Settings',
    className: 'pointer-events-none text-gray-6', // Remove to re-activate the link
    prefetch: 'intent',
    to: '/settings/companies',
  },

  // {
  //   children: 'Engineering',
  //   prefetch: 'intent',
  //   to: '/engineering',
  // },
  // {
  //   children: 'Docs',
  //   prefetch: 'intent',
  //   to: '/docs',
  // },
];
