import { Menu, Transition } from '@headlessui/react';
import { CaretDown, Plus, Star } from '@phosphor-icons/react';
import { NavLink, useLocation } from '@remix-run/react';
import { Avatar, Button, Input } from '@shiftsmartinc/shiftsmart-ui';
import { colors } from '@shiftsmartinc/style-guide';
import classnames from 'classnames';
import { Fragment, useState } from 'react';
import { useLocalStorage } from 'react-use';
import { useTypedRouteLoaderData } from 'remix-typedjson';
import { RootLoaderData } from '~/root';
import { GetProjectsList } from '~/routing/projects/actions/getProjectsList';
import { ProjectTitle } from '~/routing/projects/components/ProjectTitle';
import { shiftQueryStringDefault } from '~/routing/shifts/data/search';

export interface NavigationProjectsProps {
  className?: string;
}

/**
 * @name NavigationProjects
 * @external https://headlessui.com/react/menu
 * @description A dropdown menu component that sits in our primary navigation.
 */
export const NavigationProjects = (props: NavigationProjectsProps) => {
  const { className } = props;

  // Hooks
  const { projects } = useTypedRouteLoaderData('root') as RootLoaderData;
  const { pathname } = useLocation();
  const [search, setSearch] = useState('');
  const [favoriteProjects, setFavoriteProjects] = useLocalStorage<string[]>(
    'favoriteProjectIds',
    [],
  );

  // Setup
  const isProjects = pathname.startsWith('/projects');
  const filteredSortedProjects = projects
    .filter((project) =>
      project?.title?.toLowerCase().includes(search.toLowerCase()),
    )
    .sort(
      (project1, project2) =>
        Number(favoriteProjects?.includes(project2.uuid)) -
        Number(favoriteProjects?.includes(project1.uuid)),
    );

  // Handlers
  const redirectTo = (project: GetProjectsList) => {
    // dashboard is not a real tab yet so lets redirect to schedule
    // return `/projects/${project.uuid}/dashboard`;
    return `/projects/${project.uuid}/schedule?${shiftQueryStringDefault}`;
  };

  const onProjectFavorite = async (projectId: GetProjectsList['uuid']) => {
    const newIds = favoriteProjects?.includes(projectId)
      ? favoriteProjects.filter((id: string) => id !== projectId)
      : favoriteProjects?.concat([projectId]);

    setFavoriteProjects(newIds);
  };

  const urlProjectSetup = `/projects/create?redirectTo=${pathname}`;
  // Markup
  const renderLink = (project: GetProjectsList, index: number) => {
    const isActive = isProjects && pathname.includes(project.uuid);
    const isFavorited = favoriteProjects?.includes(project.uuid);
    const { roleType, companyName, title } = project;

    return (
      <Menu.Item key={index}>
        <NavLink
          className={classnames(
            'flex h-14 justify-between px-4 py-2 text-sm',
            isActive ? 'bg-brand-lighter text-gray-3' : 'hover:bg-gray-9',
          )}
          prefetch="intent"
          to={redirectTo(project)}
        >
          <div className="flex items-center gap-4">
            <Avatar
              className="h-9 w-9 border border-gray-8 bg-cover p-0.5"
              name={project.title}
              src={project?.companyObj?.images?.avatar ?? ''}
            />
            <ProjectTitle
              companyName={companyName}
              roleType={roleType}
              title={title}
            />
          </div>
          <Button
            intent="text"
            onClick={(e) => {
              e.preventDefault();
              onProjectFavorite(project.uuid);
            }}
            style={{ paddingRight: 0 }}
          >
            <Star
              className="h-4 w-4"
              color={colors.brand['DEFAULT']}
              weight={isFavorited ? 'fill' : 'regular'}
            />
          </Button>
        </NavLink>
      </Menu.Item>
    );
  };

  // Life Cycle

  // 🔌 Short Circuits

  return (
    <Menu
      as="div"
      className={classnames('relative inline-block text-left', className)}
    >
      {({ open }) => (
        <>
          <Menu.Button
            as="button"
            className={classnames(
              'flex items-center gap-1 whitespace-nowrap rounded-md px-[10px] py-[6px] text-sm transition-colors duration-300',
              {
                'bg-brand-lighter text-brand': isProjects || open,
                'text-gray-2 hover:bg-brand hover:text-white':
                  !isProjects || open,
              },
            )}
          >
            <span className="text-sm">Projects</span>
            <CaretDown aria-hidden="true" className="h-4 w-4" />
          </Menu.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="background-main absolute left-0 mt-2 max-h-96 w-96 origin-top-right overflow-y-auto rounded-md ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="sticky">
                <div className="background-main sticky top-0 z-10 p-4 pb-2">
                  <Input
                    id="project-search"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setSearch(event.target.value)
                    }
                    onKeyDown={(event) => {
                      if (event.key === ' ') {
                        event.stopPropagation();
                      }
                    }}
                    placeholder="Search company or role"
                    value={search}
                  />
                </div>
                <div>{filteredSortedProjects.map(renderLink)}</div>
                <div className="background-main sticky bottom-0 z-10 px-1 py-2 text-sm">
                  <Menu.Item>
                    <NavLink
                      className="ui-button brand medium text text-left"
                      prefetch="intent"
                      to={urlProjectSetup}
                    >
                      <Plus size={20} />
                      <span>Create Project</span>
                    </NavLink>
                  </Menu.Item>
                </div>
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};
