import { User, Fire, Gear, HouseSimple } from '@phosphor-icons/react';
import { NavLinkProps } from '@remix-run/react';
import { ForwardRefExoticComponent } from 'react';

export interface CommandPaletteLink extends NavLinkProps {
  Icon: ForwardRefExoticComponent<any>;
  children: string;
  key: string;
}

export const commandPaletteLinks: CommandPaletteLink[] = [
  {
    Icon: HouseSimple,
    children: 'Home',
    key: 'home',
    to: '/',
  },
  {
    Icon: User,
    children: 'Profile',
    key: 'profile',
    to: '/profile',
  },
  {
    Icon: Fire,
    children: 'Settings',
    key: 'settings',
    to: '/',
  },
  {
    Icon: Gear,
    children: 'Saved',
    key: 'saved',
    to: '/',
  },
];
