import { NavLink } from '@remix-run/react';
import { Modal } from '@shiftsmartinc/shiftsmart-ui';
import { useKeyPress, useModal } from '@shiftsmartinc/react-hooks';
import { colors } from '@shiftsmartinc/style-guide';
import { commandPaletteLinks } from '~/global/data/commandPalette';

export interface GlobalCommandPaletteProps {
  className?: string;
}

/**
 * @name GlobalCommandPalette
 * @description This is not used but would be a very handy tool for
 * getting around our website. If you've never used it before head to
 * Github.com and press "cmd + k" to see their command palette in action.
 */
export const GlobalCommandPalette = (_props: GlobalCommandPaletteProps) => {
  // Hooks
  const { openModal, closeModal, isModalOpen } = useModal('command-palette');

  // Setup

  // Handlers

  // Markup

  // Life Cycle
  useKeyPress(openModal, 'k', true);

  // 🔌 Short Circuits

  return (
    <Modal
      className="max-w-md rounded-lg bg-white text-left"
      onClose={closeModal}
      open={isModalOpen}
    >
      <input
        className="w-full border-b border-gray-7 p-4 outline-none ring-0"
        id="command"
      />
      <h3 className="p-4 pb-0 text-sm font-500 text-gray-3">Pages</h3>
      <div className="flex flex-col gap-1 p-2">
        {/* We render out some links, with keyboard hooked up its a time saver */}
        {commandPaletteLinks.map((link) => {
          const { Icon, ...rest } = link;

          return (
            <NavLink
              {...rest}
              className="flex items-center gap-2 rounded-md p-2 text-sm hover:bg-gray-9"
              key={link.key}
              onClick={closeModal}
            >
              <Icon color={colors.gray[3]} height={16} width={16} />
              <div className="text-gray-2 hover:text-gray-1">
                {link.children}
              </div>
            </NavLink>
          );
        })}
      </div>
    </Modal>
  );
};
